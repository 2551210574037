<template>
  <div class="cadastro-segurado">
    <div class="breadcrumb mb-6">
      <breadcrumb
        title="Parametrização"
        :actualPage="actualPage"
        :previousPage="previousPage.router"
        :previousPageTitle="previousPage.title"
      />
    </div>

    <router-view
      :permissions="permissions"
      :getPermissionsCompleted="getPermissionsCompleted"
    ></router-view>
    <!-- <horizontal-tabs-components :tabs="tabs" :isRouterContent="true">
    </horizontal-tabs-components> -->
  </div>
</template>
<script>
import HorizontalTabsComponents from "@/components/horizontal-tabs/HorizontalTabsComponents.vue";

import Breadcrumb from "@/layouts/components/breadcrumb/Breadcrumb.vue";
import { mapActions, mapGetters } from "vuex";
import * as Permissoes from "@/utils/permissions.js";

export default {
  components: {
    breadcrumb: Breadcrumb,
    HorizontalTabsComponents
  },
  data() {
    return {
      seguradoUniqueIdParam: "",
      tabs: [
        {
          index: 0,
          name: "Lista de modalidades",
          router: [
            "param-modalidades",
            "param-configurar-modalidade",
            "param-detalhar-modalidade"
          ]
        },
        { index: 3, name: "", router: "param-menu" },
        {
          index: 5,
          name: "Parametrização de Acesso",
          router: "param-consulta-pj"
        },
        {
          index: 4,
          name: "Perfil da corretora",
          router: "param-broker-profile"
        },
        { index: 1, name: "Pagamento", router: "param-pagamento" },
        // { index: 2, name: "Condições gerais", router: "param-condicoes" },
        {
          index: 6,
          name: "Bureaus de Consulta",
          router: "param-bureaus-consulta"
        },
        { index: 7, name: "Endosso", router: "param-endosso-list" },
        { index: 8, name: "Configuração do Endosso de Aniversário", router: "param-configurar-endosso" },
        { index: 9, name: "Endosso de Aniversário", router: "param-list-endosso" },
        { index: 10, name: "Criar modalidade de Aniversário", router: "param-criar-modalidade-aniversario" },
        { index: 11, name: "Editar modalidade de Aniversário", router: "param-editar-modalidade-aniversario" },
        { index: 12, name: "Renovação de Apólices", router: "param-renovacao-list" },
        { index: 13, name: "Configuração Geral do Módulo de Renovação", router: "param-configurar-renovacao" },
        { index: 14, name: "Lista modalidades renovação", router: "param-list-renovacao" },
        { index: 15, name: "Criar modalidade de Renovação", router: "param-criar-modalidade-renovacao" },
        { index: 16, name: "Editar modalidade de Renovação", router: "param-editar-modalidade-renovacao" },
      ],
      getPermissionsCompleted: false
      
    };
  },
  computed: {
    ...mapGetters("parametrizacao-module", ["permissions"]),

    actualPage() {
      if (
        ["param-configurar-modalidade", "param-detalhar-modalidade"].includes(
          this.$route.name
        )
      )
        return "param-detalhar-modalidade" == this.$route.name
          ? "Cadastrar configuração"
          : "Editar configuração";
      else if (["parametrizacao-bureaus"].includes(this.$route.name))
        return "Parametrização de Bureau";
      else
        return this.tabs.filter(x =>
          Array.isArray(x.router)
            ? x.router.includes(this.$route.name)
            : this.$route.name == x.router
        )[0].name;
    },

    previousPage() {
      if (
        ["param-configurar-modalidade", "param-detalhar-modalidade"].includes(
          this.$route.name
        )
      )
        return {
          router: "parametrizacao/modalidades",
          title: "Lista de modalidades"
        };
      else if (
        ["param-modalidades", "param-broker-profile", "param-bureaus-consulta", "param-consulta-pj", "param-list-endosso", "param-endosso-list", "param-configurar-endosso", "param-criar-modalidade-aniversario", "param-editar-modalidade-aniversario", "param-renovacao-list", "param-configurar-renovacao", "param-list-renovacao" , "param-criar-modalidade-renovacao", "param-editar-modalidade-renovacao" ].includes(this.$route.name)
      )
        return {
          router: "parametrizacao/menu",
          title: "Lista de parâmetros"
        };
      else if (["parametrizacao-bureaus"].includes(this.$route.name))
        return {
          router: "parametrizacao/bureaus-consulta",
          title: "Bureaus de Consulta"
        };
      else return { router: null, title: null };
    }
  },
  methods: {
    ...mapActions("parametrizacao-module", ["getPermissions"])
  },
  async created() {
    this.$appInsights.trackPageView({
      name: "parametrizacao",
      url: window.location.href
    });

    await this.$onpoint.loading(async () => {
      return await this.getPermissions(Permissoes.Modulo.PARAMETRIZACAO).then(
        () => (this.getPermissionsCompleted = true)
      );
    });
  }
};
</script>

<style lang="scss" scoped>
.enable-ccg .material-icons {
  font-size: 0.85rem;
  color: rgba(var(--vs-warning), 1);
  font-size: 0.8em;
}
</style>
